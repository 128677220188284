import React from 'react'
import { Loader } from './Loader'
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { Banner } from './Banner'
import LiveStreamCard from './LiveStreamCard'
import Form from './Form'

export default class LiveStream extends React.Component {
	state = {
		dayFilter: null,
		filteredSessions: null,
	}

	componentWillMount() {
		window.scrollTo(0, 0)
		this.fetchSession()
	}

	fetchSession = async () => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getSessions')
		const filteredSessions = data.data.sessions.filter(session => session.LiveStream === 'Yes')

		this.setState({
			sessions: filteredSessions,
			filteredSessions: filteredSessions,
		})
	}

	sortResults = data => {
		return data.sort(function(a, b) {
			if (a.Title < b.Title && a.SortDate < b.SortDate) {
				return -1
			}
			if (a.Title > b.Title && a.SortDate > b.SortDate) {
				return 1
			}
			return 0
		})
	}

	setDayFilter = filter => {
		const { sessions } = this.state
		const filteredSessions = !filter ? sessions : sessions.filter(session => session.Date.includes(filter))

		this.setState({
			dayFilter: filter,
			filteredSessions: filteredSessions,
		})
	}

	render() {
		const { dayFilter, filteredSessions } = this.state
		const meta =
			'Never miss a moment of the 2020 ASU GSV Summit. View the live stream schedule and sign up to watch must-see content in real time.'

		return (
			<div>
				<MetaTags>
					<title>Live Stream | ASU GSV Summit</title>
					<meta name='description' content={meta} />
					<meta name='twitter:title' content='Live Stream' />
					<meta name='twitter:description' content={meta} />
					<meta property='og:title' content='Live Stream' />
					<meta property='og:description' content={meta} />
				</MetaTags>
				<Banner
					title='Join the live stream'
					subTitle='Never miss a moment of the 2020 ASU GSV Summit. View the live stream schedule and sign up to watch our live streams in real time. Our team will email you a password that will give you access to our must-see content.'
					image='/images/livestream.jpg'
				/>
				<div className='livestream-wrapper'>
					<h1 className='livestream-title'>Sign up to view the live stream</h1>
					<Form />
					<h1 className='livestream-title'>Sessions</h1>
					<div>
						<div className='day-container day-container-livestream'>
							<button
								className={`details-button w-padding ${
									!dayFilter ? 'details-button-active' : 'details-button-inverse'
								} session-tabs`}
								onClick={() => this.setDayFilter(null)}
							>
								All
							</button>
							<button
								className={`details-button w-padding ${
									dayFilter === 'Sunday' ? 'details-button-active' : 'details-button-inverse'
								} session-tabs`}
								onClick={() => this.setDayFilter('Sunday')}
							>
								Sunday, Mar 29
							</button>
							<button
								className={`details-button w-padding ${
									dayFilter === 'Monday' ? 'details-button-active' : 'details-button-inverse'
								} session-tabs`}
								onClick={() => this.setDayFilter('Monday')}
							>
								Monday, Mar 30
							</button>
							<button
								className={`details-button w-padding ${
									dayFilter === 'Tuesday' ? 'details-button-active' : 'details-button-inverse'
								} session-tabs`}
								onClick={() => this.setDayFilter('Tuesday')}
							>
								Tuesday, Mar 31
							</button>
							<button
								className={`details-button w-padding ${
									dayFilter === 'Wednesday' ? 'details-button-active' : 'details-button-inverse'
								} session-tabs`}
								onClick={() => this.setDayFilter('Wednesday')}
							>
								Wednesday, Apr 1
							</button>
						</div>
						<div className='companies-grid'>
							{!filteredSessions ? (
								<Loader />
							) : filteredSessions.length ? (
								this.sortResults(filteredSessions).map((session, i) => (
									<LiveStreamCard session={session} key={i} />
								))
							) : (
								<h1 className='error-message'>no sesions match these filters</h1>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
