import React from 'react'

export const Header = () => (
	<div className='header-container header-container-desktop'>
		<section>
			<a className='header-logo' href='https://www.asugsvsummit.com'>
				<img
					src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e4564b8f1fe362186233bb3_ASUGSVlogo.svg'
					alt='ASU GSV Summit 2020'
				/>
			</a>
		</section>
		<section>
			<ul>
				<li className='header-label'>
					<h4>About</h4>
					<img className='caret' src='/images/down.png' />
				</li>
				<div className='inactive-dropdown dropdown-hover'>
					<li>
						<a href='https://www.asugsvsummit.com/summit-details'>Summit Details</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/about-the-summit'>About the Summit</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/faqs'>FAQs</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/honorees'>Honorees</a>
					</li>
				</div>
			</ul>
			<ul>
				<li className='header-label'>
					<h4>Summit Program</h4>
					<img className='caret' src='/images/down.png' />
				</li>
				<div className='inactive-dropdown dropdown-hover'>
					<li>
						<a href='https://www.asugsvsummit.com/why-attend'>Why Attend</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/gsv-cup'>GSV Cup Elite 200</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/agenda/agenda-at-a-glance'>Agenda-at-a-Glance</a>
					</li>
					<li>
						<a href='/live-stream/schedule'>Live Stream</a>
					</li>
					<li>
						<a href='/agenda/sessions'>Program</a>
					</li>
					<li>
						<a href='/speakers'>Speakers</a>
					</li>
					<li>
						<a href='/companies'>Companies</a>
					</li>
					<li>
						<a href='/partners'>Partners</a>
					</li>
				</div>
			</ul>
			<ul>
				<li className='header-label'>
					<h4>Media</h4>
					<img className='caret' src='/images/down.png' />
				</li>
				<div className='inactive-dropdown dropdown-hover'>
					<li>
						<a href='https://www.asugsvsummit.com/news'>News</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/video'>Video</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/podcast'>Podcast</a>
					</li>
					<li>
						<a href='https://gsvsummit.smugmug.com/ASU-GSV/' target='_blank' rel='noopener'>
							Gallery
						</a>
					</li>
				</div>
			</ul>
			<ul>
				<li>
					<a href='https://www.asugsvsummit.com/become-a-sponsor'>Sponsor Inquiries</a>
				</li>
			</ul>

			<ul>
				<li>
					<a href='https://gsv.ventures/' target='_blank' rel='noopener'>
						GSV Ventures
					</a>
				</li>
			</ul>
			<ul>
				<li>
					<a
						href='https://www.asugsvsummit.com/asu-gsv-summit-health-safety-statement'
						className='details-button details-button-header health-safety-button'
					>
						ASU + GSV Summit
						<br />
						Health & Safety Statement
					</a>
				</li>
			</ul>
			<ul>
				<li>
					<a
						href='https://web.cvent.com/event/2e92f050-d645-4cd4-8bfb-5fdca71826fc/summary?rp=00000000-0000-0000-0000-000000000000'
						targt='_blank'
						className='details-button details-button-header'
					>
						Register for 2020
					</a>
				</li>
			</ul>
			<ul>
				<li>
					<a href='/search'>
						<img
							className='search'
							src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e250c985cbbd66489249423_socialf70177_search.svg'
						/>
					</a>
				</li>
			</ul>
		</section>
	</div>
)
