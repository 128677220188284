import React from 'react'

export default class AgendaScheduleHold extends React.Component {
	componentWillMount() {
		window.location.href = 'https://www.asugsvsummit.com/agenda/schedule'
	}

	render() {
		return <div />
	}
}
