import React from 'react'

export const Banner = props => (
	<div
		className='large-banner'
		style={{ backgroundImage: `url("https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e1372fdc017e62f970c2033_Rectangle%203309.svg"), url(${props.image})` }}
	>
		<h1>{props.title}</h1>
		{props.subTitle ? <h2>{props.subTitle}</h2> : ''}
		{props.withButton ? (
			<a
				href='https://www.asugsvsummit.com/become-a-sponsor'
				className='details-button w-padding hero-button details-button-hero'
			>
				Become a Sponsor
			</a>
		) : (
			''
		)}
	</div>
)
