import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

export default class SessionCard extends React.Component {
	state = {
		location: null,
		expandDescription: false,
		RoomId: null,
	}

	componentDidMount() {
		const { RoomId } = this.props.session
		this.fetchLocation(RoomId)
		this.setState({
			RoomId: RoomId,
		})

	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.session.RoomId !== this.state.RoomId) {
			this.setState({
				RoomId: nextProps.session.RoomId,
			})
			this.fetchLocation(nextProps.session.RoomId)
		}
	}

	fetchLocation = async RoomId => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getLocation', {
			params: {
				id: RoomId,
			},
		})

		this.setState({
			location: data.data.location,
		})
	}

	formatDescription = data => {
		const doc = new DOMParser().parseFromString(data, 'text/xml')
		return doc.firstChild.innerHTML
	}

	formatSpeakers = data => {
		return data
			.map(speaker => {
				if (speaker.FirstName === '.') {
					return `${speaker.LastName}`
				} else {
					return `${speaker.FirstName} ${speaker.LastName}`
				}
			})
			.join(', ')
	}

	createMarkup = data => {
		const replaceBreaks = `${data.replace(/(?:\r\n|\r|\n)/g, '<br/>')}`
		return { __html: replaceBreaks }
	}

	generatedName = name => {
		return name.replace(/ /g, '-').replace(/[^a-zA-Z0-9-_]/g, '')
	}

	expandDescription = () => {
		this.setState({
			expandDescription: !this.state.expandDescription,
		})
	}

	render() {
		const { session } = this.props
		const { location, expandDescription } = this.state

		return (
			<div className='custom-session-container'>
				<section>
					<h1 className='session-title-mobile'>
						<Link
							to={`/agenda/sessions/${session.Id}/${this.generatedName(session.Title)}`}
							className='session-title-link'
						>
							{session.Title}
						</Link>
					</h1>
					<h3>{session.Date}</h3>
					<h3>{session.Time} PST</h3>
					<h3>{location ? location : 'Loading location...'}</h3>
					{session.LiveStream === 'Yes' ? (
						<a
							href='/live-stream/schedule'
							className='details-button details-button-inverse live-stream-button'
						>
							Live Stream
						</a>
					) : (
						''
					)}
				</section>
				<section>
					<h1>
						<Link
							to={`/agenda/sessions/${session.Id}/${this.generatedName(session.Title)}`}
							className='session-title-link'
						>
							{session.Title}
						</Link>
						{expandDescription ? (
							<img src='/images/minus.png' onClick={this.expandDescription} />
						) : (
							<img src='/images/plus.png' onClick={this.expandDescription} />
						)}
					</h1>

					<article
						className={expandDescription ? 'expanded-article' : 'cropped-article'}
						dangerouslySetInnerHTML={this.createMarkup(session.Description)}
					/>
					<div className='session-card-details'>
						<h4>{this.formatSpeakers(session.Speakers)}</h4>
						<Link
							to={`/agenda/sessions/${session.Id}/${this.generatedName(session.Title)}`}
							className='details-button live-stream-button'
						>
							Details
						</Link>
					</div>
				</section>
			</div>
		)
	}
}
