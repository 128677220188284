import React from 'react'
import { CompanyCard } from './CompanyCard'
import CompaniesFilters from './CompaniesFilters'
import { Paginate } from './Paginate'
import { Loader } from './Loader'
import { Banner } from './Banner'
import MetaTags from 'react-meta-tags'
import axios from 'axios'

export default class Companies extends React.Component {
	state = {
		title: 'Congratulations to the Finalists!',
		subTitle:
			'The GSV Cup Finalists are bootstrapped and seed stage companies that have been selected to present for 5 minutes at the 2020 ASU GSV Summit from March 30th to April 1st in San Diego this year.  Three companies will be chosen as finalists during the Summit, receiving a prize package of $250,000 cash and $100,000 in Google Cloud credits.',
		companies: [],
		filteredCompanies: [],
		startIndex: 0,
		endIndex: 50,
		filter: 'GSV Cup Elite 200',
	}

	componentWillMount() {
		window.scrollTo(0, 0)
		this.fetchCompanies()
	}

	fetchCompanies = async () => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getCompanies')
		this.setState({
			companies: data.data.companies,
			filteredCompanies: data.data.filteredCompanies,
		})
	}

	covertFilterToId = filter => {
		if (filter === 'GSV Cup Elite 200') {
			return 5417
		} else if (filter === 'Series A+') {
			return 5418
		} else if (filter === 'Growth') {
			return 5419
		}
	}

	setBannerContent = filter => {
		let title
		let subTitle

		if (filter === 'GSV Cup Elite 200') {
			title = 'Congratulations to the Finalists!'
			subTitle =
				'The GSV Cup Finalists are bootstrapped and seed stage companies that have been selected to present for 5 minutes at the 2020 ASU GSV Summit from March 30th to April 1st in San Diego this year.  Three companies will be chosen as finalists during the Summit, receiving a prize package of $250,000 cash and $100,000 in Google Cloud credits.'
		} else if (filter === 'Series A+') {
			title = null
			subTitle =
				'The Series A+ track showcases premier early stage, venture-backed companies across PreK-12, Higher Ed, and Workforce with CEO’s giving 7 minute presentations.'
		} else if (filter === 'Growth') {
			title = null
			subTitle =
				'The Growth Track shines a light on elite late stage venture-backed companies across the entire “PreK Through Gray” spectrum with CEO’s giving 12 minute presentations.'
		}
		this.setState({
			title: title,
			subTitle: subTitle,
		})
	}

	filterCompanies = filter => {
		const { companies } = this.state
		const filterId = this.covertFilterToId(filter)
		const filteredCompanies = companies
			.map(company => {
				const roles = company.UserCustomRoles.map(role => role.CustomRoleId)
				if (roles.includes(filterId)) {
					return company
				}
			})
			.filter(Boolean)
		this.setBannerContent(filter)
		this.setState({
			filter: filter,
			filteredCompanies: filteredCompanies,
			startIndex: 0,
			endIndex: 50,
		})
	}

	prev = () => {
		window.scrollTo(0, 0)
		let { startIndex, endIndex } = this.state

		if (endIndex - 50 > 0) {
			this.setState({
				startIndex: (startIndex -= 50),
				endIndex: (endIndex -= 50),
			})
		}
	}

	next = () => {
		window.scrollTo(0, 0)
		let { filteredCompanies, startIndex, endIndex } = this.state
		const maxIndex = Math.ceil(filteredCompanies.length)

		if (startIndex + 50 <= maxIndex) {
			this.setState({
				startIndex: (startIndex += 50),
				endIndex: (endIndex += 50),
			})
		}
	}

	sortResults = data => {
		return data.sort(function(a, b) {
			if (a.LastName < b.LastName) {
				return -1
			}
			if (a.LastName > b.LastName) {
				return 1
			}
			return 0
		})
	}

	render() {
		const { filter, filteredCompanies, startIndex, endIndex, title, subTitle } = this.state
		const meta =
			'ASU GSV Summit is the most impactful convening of leaders in education and talent technology. The event attracts 5,000+ attendees across the “PreK to Gray” learning and talent innovation spectrum and focuses on advancing equity and access to everyone.'

		return (
			<div>
				<MetaTags>
					<title>Companies | ASU GSV Summit</title>
					<meta name='description' content={meta} />
					<meta name='twitter:title' content='companies' />
					<meta name='twitter:description' content={meta} />
					<meta property='og:title' content='companies' />
					<meta property='og:description' content={meta} />
				</MetaTags>
				<Banner
					title='companies'
					image='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5df03456f4feab251f2ab115_companiesmain.jpg'
				/>
				<div className='main-wrapper company-single-page'>
					<CompaniesFilters passCompanyFilter={this.filterCompanies} />
					<h1>{filter}</h1>
					<div className='companies-banner'>
						{title && <h2>{title}</h2>}
						<p>{subTitle}</p>
					</div>
					<div className='companies-grid'>
						{filteredCompanies.length ? (
							this.sortResults(filteredCompanies)
								.slice(startIndex, endIndex)
								.map((company, i) => <CompanyCard key={i} company={company} />)
						) : (
							<Loader />
						)}
					</div>
					{filteredCompanies.length ? (
						<Paginate
							large
							next={this.next}
							prev={this.prev}
							startIndex={startIndex}
							endIndex={endIndex}
							inc={50}
							maxIndex={Math.ceil(filteredCompanies.length)}
						/>
					) : (
						''
					)}
				</div>
			</div>
		)
	}
}
