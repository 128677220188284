import React from 'react'
import Search from './Search'
import axios from 'axios'
import { Loader } from './Loader'
import SessionCard from './SessionCard'
import SpeakerCardLarge from './SpeakerCardLarge'
import { CompanyCard } from './CompanyCard'
import { SponsorCard } from './SponsorCard'
import MetaTags from 'react-meta-tags'
import CompaniesFilters from './CompaniesFilters'
import SponsorFilters from './SponsorFilters'
import SpeakerFilters from './SpeakerFilters'
import { DayFilters } from './DayFilters'

export default class Results extends React.Component {
	state = {
		companies: [],
		sessions: [],
		speakers: [],
		sponsors: [],
		companyResults: [],
		sessionResults: [],
		speakerResults: [],
		sponsorResults: [],
		filteredCompanies: null,
		filteredSponsors: null,
		filteredSessions: null,
		filteredSpeakers: null,
		clearInput: false,
		readyState: false,
		dayFilter: null,
	}

	componentDidMount() {
		window.scrollTo(0, 0)
		this.fetchSponsors()
		this.fetchSpeakers()
		this.fetchCompanies()
		this.fetchSession()
	}

	fetchSession = async () => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getSessions')
		this.setState({
			sessions: data.data.sessions,
			readyState: true,
		})
	}

	fetchCompanies = async () => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getCompanies')
		this.setState({
			companies: data.data.companies,
		})
	}

	fetchSponsors = async () => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getSponsors')
		this.setState({
			sponsors: data.data.sponsors,
		})
	}

	fetchSpeakers = async () => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getSpeakers')
		this.setState({
			speakers: data.data.speakers,
		})
	}

	filterSessions = sessions => {
		this.setState({
			filteredSessions: sessions,
			sessionResults: sessions,
		})
	}

	filterCompanies = companies => {
		this.setState({
			filteredCompanies: companies,
			companyResults: companies,
		})
	}

	filterSpeakers = speakers => {
		this.setState({
			filteredSpeakers: speakers,
			speakerResults: speakers,
		})
	}

	filterSponsors = sponsors => {
		this.setState({
			filteredSponsors: sponsors,
			sponsorResults: sponsors,
		})
	}

	sortSponsors = data => {
		return data.sort(function(a, b) {
			if (a.CompanyName < b.CompanyName) {
				return -1
			}
			if (a.CompanyName > b.CompanyName) {
				return 1
			}
			return 0
		})
	}

	sortSessions = data => {
		return data.sort(function(a, b) {
			if (a.Title < b.Title && a.SortDate < b.SortDate) {
				return -1
			}
			if (a.Title > b.Title && a.SortDate > b.SortDate) {
				return 1
			}
			return 0
		})
	}

	sortSpeakers = data => {
		return data.sort(function(a, b) {
			if (a.LastName < b.LastName) {
				return -1
			}
			if (a.LastName > b.LastName) {
				return 1
			}
			return 0
		})
	}

	sortCompanies = data => {
		return data.sort(function(a, b) {
			if (a.LastName < b.LastName) {
				return -1
			}
			if (a.LastName > b.LastName) {
				return 1
			}
			return 0
		})
	}

	covertFilterToId = filter => {
		if (filter === 'GSV Cup Elite 200') {
			return 5417
		} else if (filter === 'Series A+') {
			return 5418
		} else if (filter === 'Growth') {
			return 5419
		}
	}

	companiesByType = filter => {
		const { companyResults } = this.state
		const filterId = this.covertFilterToId(filter)
		const filteredCompanies = companyResults
			.map(company => {
				const roles = company.UserCustomRoles.map(role => role.CustomRoleId)
				if (roles.includes(filterId)) {
					return company
				}
			})
			.filter(Boolean)

		this.setState({
			filteredCompanies: filteredCompanies,
		})
	}

	sponsorsByType = filter => {
		const { sponsorResults } = this.state
		let filteredSponsors
		if (filter === 'Collaborating') {
			filteredSponsors = sponsorResults.filter(sponsor => sponsor.SponsorType.Title === 'Collaborating')
		} else {
			filteredSponsors = sponsorResults.filter(sponsor => sponsor.SponsorType.Title !== 'Collaborating')
		}
		this.setState({
			filteredSponsors: filteredSponsors,
		})
	}

	getKeynoteValue = speaker => {
		const isKeynote = speaker.PropertyValues.filter((obj, i) => obj.PropertyMetadata.Title === 'Keynote speaker')
		return isKeynote.length && isKeynote[0].Value === 'yes' ? true : false
	}

	speakersByType = filter => {
		const { speakerResults } = this.state
		let filteredSpeakers

		if (filter === 'Keynote Speakers') {
			filteredSpeakers = speakerResults.filter(speaker => this.getKeynoteValue(speaker))
		} else {
			filteredSpeakers = speakerResults
		}

		this.setState({
			filteredSpeakers: filteredSpeakers,
		})
	}

	setDayFilter = filter => {
		const { sessionResults } = this.state
		const filteredSessions = !filter
			? sessionResults
			: sessionResults.filter(session => session.Date.includes(filter))

		this.setState({
			dayFilter: filter,
			filteredSessions: filteredSessions,
		})
	}

	render() {
		const {
			companies,
			speakers,
			sponsors,
			sessions,
			companyResults,
			speakerResults,
			sponsorResults,
			sessionResults,
			filteredCompanies,
			filteredSponsors,
			filteredSessions,
			filteredSpeakers,
			readyState,
			dayFilter,
		} = this.state
		const meta =
			'ASU GSV Summit is the most impactful convening of leaders in education and talent technology. The event attracts 5,000+ attendees across the "PreK to Gray" learning and talent innovation spectrum and focuses on advancing equity and access to everyone.'

		return readyState ? (
			<div className='main-wrapper search-page-wrapper'>
				<MetaTags>
					<title>Search | ASU GSV Summit</title>
					<meta name='description' content={meta} />
					<meta name='twitter:title' content='Search' />
					<meta name='twitter:description' content={meta} />
					<meta property='og:title' content='Search' />
					<meta property='og:description' content={meta} />
				</MetaTags>
				<Search
					fullSearch
					sessions={sessions}
					companies={companies}
					speakers={speakers}
					sponsors={sponsors}
					filterSessions={this.filterSessions}
					filterCompanies={this.filterCompanies}
					filterSpeakers={this.filterSpeakers}
					filterSponsors={this.filterSponsors}
				/>

				{filteredCompanies ? (
					<div className='grid-padding' style={{ margin: `${companyResults.length ? '50px 0' : '0'}` }}>
						{companyResults.length ? <h5>Companies Results:</h5> : ''}
						{companyResults.length ? <CompaniesFilters passCompanyFilter={this.companiesByType} /> : ''}
						<div style={{ marginLeft: '-20px' }}>
							<div className='companies-grid'>
								{filteredCompanies.map((company, i) => (
									<CompanyCard key={i} company={company} />
								))}
							</div>
						</div>
					</div>
				) : (
					''
				)}

				{filteredSponsors ? (
					<div className='grid-padding' style={{ margin: `${sponsorResults.length ? '50px 0' : '0'}` }}>
						{sponsorResults.length ? <h5>Sponsors Results:</h5> : ''}
						{sponsorResults.length ? <SponsorFilters passSponsorFilter={this.sponsorsByType} /> : ''}
						<div style={{ marginLeft: '-20px' }}>
							<div className='companies-grid'>
								{filteredSponsors.map((sponsor, i) => (
									<SponsorCard key={i} sponsor={sponsor} />
								))}
							</div>
						</div>
					</div>
				) : (
					''
				)}

				{filteredSpeakers ? (
					<div className='grid-padding' style={{ margin: `${speakerResults.length ? '50px 0' : '0'}` }}>
						{speakerResults.length ? <h5>Speakers Results:</h5> : ''}
						{speakerResults.length ? <SpeakerFilters passSpeakerFilter={this.speakersByType} /> : ''}
						<div className='companies-grid'>
							{filteredSpeakers.map((speaker, i) => (
								<SpeakerCardLarge key={i} speaker={speaker} />
							))}
						</div>
					</div>
				) : (
					''
				)}

				{filteredSessions ? (
					<div
						className='grid-padding session-grid-padding'
						style={{ margin: `${sessionResults.length ? '50px 0' : '0'}` }}
					>
						{sessionResults.length ? <h5>Session Results:</h5> : ''}
						{sessionResults.length ? (
							<DayFilters setDayFilter={this.setDayFilter} dayFilter={dayFilter} />
						) : (
							''
						)}
						<div style={{ marginTop: '60px' }}>
							{this.sortSessions(sessionResults).map((session, i) => (
								<SessionCard session={session} key={i} />
							))}
						</div>
					</div>
				) : (
					''
				)}
			</div>
		) : (
			<div className='main-wrapper'>
				<Loader />
			</div>
		)
	}
}
