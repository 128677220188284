import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

export default class LiveStreamCard extends React.Component {
	componentDidMount() {
		this.fetchLocation()
	}

	fetchLocation = async () => {
		const { RoomId } = this.props.session
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getLocation', {
			params: {
				id: RoomId,
			},
		})

		this.setState({
			location: data.data.location,
		})
	}

	generatedName = name => {
		return name.replace(/ /g, '-').replace(/[^a-zA-Z0-9-_]/g, '')
	}

	formatSpeakers = data => {
		return data
			.map(speaker => {
				if (speaker.FirstName === '.') {
					return `${speaker.LastName}`
				} else {
					return `${speaker.FirstName} ${speaker.LastName}`
				}
			})
			.join(', ')
	}

	render() {
		const { session } = this.props

		return (
			<div className='livestream-container'>
				<section>
					<h1>
						<Link
							to={`/agenda/sessions/${session.Id}/${this.generatedName(session.Title)}`}
							className='session-title-link'
						>
							{session.Title}
						</Link>
					</h1>
					<h4>{this.formatSpeakers(session.Speakers)}</h4>
					<h3>{session.Date}</h3>
					<h3>{session.Time} PST</h3>
				</section>
				<div className='session-card-details'>
					<Link
						to={`/agenda/sessions/${session.Id}/${this.generatedName(session.Title)}`}
						className='details-button live-stream-button'
					>
						Details
					</Link>
				</div>
			</div>
		)
	}
}
