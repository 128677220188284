import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Main from './Components/Main'
import AgendaScheduleHold from './Components/AgendaScheduleHold'
// import Sessions from './Components/Sessions'
// import SingleSession from './Components/SingleSession'
import LiveStream from './Components/LiveStream'
import SingleSpeaker from './Components/SingleSpeaker'
import Companies from './Components/Companies'
import Sponsors from './Components/Sponsors'
import SingleCompany from './Components/SingleCompany'
import Speakers from './Components/Speakers'
import Error from './Components/Error'
import Results from './Components/Results'

const routes_with_components = [
	{
		path: '/',
		exact: true,
		component: Main,
	},
	// {
	// 	path: '/agenda/sessions',
	// 	exact: true,
	// 	component: Sessions,
	// },
	{
		path: '/agenda/sessions',
		exact: true,
		component: AgendaScheduleHold,
	},
	// {
	// 	path: '/agenda/sessions/:id/:name',
	// 	exact: true,
	// 	component: SingleSession,
	// },
	{
		path: '/agenda/sessions/:id/:name',
		exact: true,
		component: AgendaScheduleHold,
	},
	{
		path: '/companies',
		exact: true,
		component: Companies,
	},
	{
		path: '/companies/:id/:name',
		exact: true,
		component: SingleCompany,
	},
	{
		path: '/speakers',
		exact: true,
		component: Speakers,
	},
	{
		path: '/speakers/:id/:name',
		exact: true,
		component: SingleSpeaker,
	},
	{
		path: '/partners',
		exact: true,
		component: Sponsors,
	},
	// {
	// 	path: '/live-stream/schedule',
	// 	exact: true,
	// 	component: LiveStream,
	// },
	{
		path: '/live-stream/schedule',
		exact: true,
		component: AgendaScheduleHold,
	},
	{
		path: '/live-stream',
		exact: true,
		component: AgendaScheduleHold,
	},
	{
		path: '/search',
		exact: true,
		component: Results,
	},
	{
		path: '*',
		component: Error,
	},
]

export default (
	<Switch>
		{routes_with_components.map((route, i) => (
			<Route
				key={i}
				exact={route.exact}
				path={route.path}
				render={props => {
					return <route.component {...props} />
				}}
			/>
		))}
	</Switch>
)
