import React from 'react'

export const DayFilters = props => {
	const { dayFilter } = props
	return (
		<div className='day-container'>
			<button
				className={`details-button w-padding ${
					!dayFilter ? 'details-button-active' : 'details-button-inverse'
				} session-tabs`}
				onClick={() => props.setDayFilter(null)}
			>
				All
			</button>
			<button
				className={`details-button w-padding ${
					dayFilter === 'Sunday' ? 'details-button-active' : 'details-button-inverse'
				} session-tabs`}
				onClick={() => props.setDayFilter('Sunday')}
			>
				Sunday, Mar 29
			</button>
			<button
				className={`details-button w-padding ${
					dayFilter === 'Monday' ? 'details-button-active' : 'details-button-inverse'
				} session-tabs`}
				onClick={() => props.setDayFilter('Monday')}
			>
				Monday, Mar 30
			</button>
			<button
				className={`details-button w-padding ${
					dayFilter === 'Tuesday' ? 'details-button-active' : 'details-button-inverse'
				} session-tabs`}
				onClick={() => props.setDayFilter('Tuesday')}
			>
				Tuesday, Mar 31
			</button>
			<button
				className={`details-button w-padding ${
					dayFilter === 'Wednesday' ? 'details-button-active' : 'details-button-inverse'
				} session-tabs`}
				onClick={() => props.setDayFilter('Wednesday')}
			>
				Wednesday, Apr 1
			</button>
		</div>
	)
}
