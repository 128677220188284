import React from 'react'

export default class SpeakerFilters extends React.Component {
	state = {
		filter: 'All Speakers',
	}

	updateFilter = filter => {
		this.setState({
			filter: filter,
		})
		this.props.passSpeakerFilter(filter)
	}

	render() {
		const { filter } = this.state
		return (
			<div className='filters-wrapper-large' style={{ marginBottom: '60px' }}>
				<button
					className={`details-button w-padding details-button-large session-tabs ${
						filter === 'All Speakers' ? 'details-button-active' : 'details-button-inverse'
					}`}
					onClick={() => this.updateFilter('All Speakers')}
				>
					All Speakers
				</button>
				<button
					className={`details-button w-padding details-button-large session-tabs ${
						filter === 'Keynote Speakers' ? 'details-button-active' : 'details-button-inverse'
					}`}
					onClick={() => this.updateFilter('Keynote Speakers')}
				>
					Keynote Speakers
				</button>
			</div>
		)
	}
}
