import React from 'react'

export default class MobileHeader extends React.Component {
	state = {
		activeHeader: null,
		openMenu: false,
	}

	setActiveHeader = header => {
		const { activeHeader } = this.state
		if (header === activeHeader) {
			this.setState({
				activeHeader: null,
			})
		} else {
			this.setState({
				activeHeader: header,
			})
		}
	}

	toggleMenu = () => {
		this.setState({
			openMenu: !this.state.openMenu,
		})
	}

	render() {
		const { activeHeader, openMenu } = this.state
		return (
			<div className='header-container header-container-mobile'>
				<section>
					<a className='header-logo' href='https://www.asugsvsummit.com'>
						<img
							src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e4564b8f1fe362186233bb3_ASUGSVlogo.svg'
							alt='ASU GSV Summit 2020'
						/>
					</a>
					<img className='hamburger-icon' src='/images/menu.png' onClick={this.toggleMenu} />
				</section>
				{openMenu ? (
					<section>
						<ul>
							<li className='header-label' onClick={() => this.setActiveHeader('about')}>
								<h4>About</h4>
								<img className='caret' src='/images/down.png' />
							</li>
							<div className={`${activeHeader === 'about' ? 'active-dropdown' : 'inactive-dropdown'}`}>
								<li>
									<a href='https://www.asugsvsummit.com/summit-details'>Summit Details</a>
								</li>
								<li>
									<a href='https://www.asugsvsummit.com/about-the-summit'>About the Summit</a>
								</li>
								<li>
									<a href='https://www.asugsvsummit.com/faqs'>FAQs</a>
								</li>
								<li>
									<a href='https://www.asugsvsummit.com/honorees'>Honorees</a>
								</li>
							</div>
						</ul>
						<ul>
							<li className='header-label' onClick={() => this.setActiveHeader('program')}>
								<h4>Summit Program</h4>
								<img className='caret' src='/images/down.png' />
							</li>
							<div className={`${activeHeader === 'program' ? 'active-dropdown' : 'inactive-dropdown'}`}>
								<li>
									<a href='https://www.asugsvsummit.com/why-attend'>Why Attend</a>
								</li>
								<li>
									<a href='https://www.asugsvsummit.com/gsv-cup'>GSV Cup Elite 200</a>
								</li>
								<li>
									<a href='https://www.asugsvsummit.com/agenda/agenda-at-a-glance'>Agenda-at-a-Glance</a>
								</li>
								<li>
									<a href='/live-stream/schedule'>Live Stream</a>
								</li>
								<li>
									<a href='/agenda/sessions'>Program</a>
								</li>
								<li>
									<a href='/speakers'>Speakers</a>
								</li>
								<li>
									<a href='/companies'>Companies</a>
								</li>
								<li>
									<a href='/partners'>Partners</a>
								</li>
							</div>
						</ul>
						<ul>
							<li className='header-label' onClick={() => this.setActiveHeader('media')}>
								<h4>Media</h4>
								<img className='caret' src='/images/down.png' />
							</li>
							<div className={`${activeHeader === 'media' ? 'active-dropdown' : 'inactive-dropdown'}`}>
								<li>
									<a href='https://www.asugsvsummit.com/news'>News</a>
								</li>
								<li>
									<a href='https://www.asugsvsummit.com/video'>Video</a>
								</li>
								<li>
									<a href='https://www.asugsvsummit.com/podcast'>Podcast</a>
								</li>
								<li>
									<a href='https://gsvsummit.smugmug.com/ASU-GSV/' target='_blank' rel='noopener'>
										Gallery
									</a>
								</li>
							</div>
						</ul>
						<ul>
							<li className='external-link'>
								<a href='https://www.asugsvsummit.com/become-a-sponsor'>
									<h4>Sponsor Inquiries</h4>
								</a>
							</li>
						</ul>

						<ul>
							<li className='external-link'>
								<a href='https://gsv.ventures/' target='_blank' rel='noopener'>
									<h4>GSV Ventures</h4>
								</a>
							</li>
						</ul>
						<ul>
							<li>
								<a
									href='https://www.asugsvsummit.com/asu-gsv-summit-health-safety-statement'
									className='details-button details-button-header health-safety-button'
								>
									ASU + GSV Summit
									<br />
									Health & Safety Statement
								</a>
							</li>
						</ul>
						<ul>
							<li>
								<a
									href='https://web.cvent.com/event/2e92f050-d645-4cd4-8bfb-5fdca71826fc/summary?rp=00000000-0000-0000-0000-000000000000'
									className='details-button details-button-header'
								>
									Register for 2020
								</a>
							</li>
						</ul>
						<ul>
							<li>
								<a href='/search'>
									<img
										className='search'
										src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e250c985cbbd66489249423_socialf70177_search.svg'
									/>
								</a>
							</li>
						</ul>
					</section>
				) : (
					''
				)}
			</div>
		)
	}
}
