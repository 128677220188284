import React from 'react'
import SessionCard from './SessionCard'
import { Loader } from './Loader'
import MetaTags from 'react-meta-tags'
import axios from 'axios'

export default class SingleSpeaker extends React.Component {
	state = {
		speaker: null,
		sessions: null,
	}

	componentWillMount() {
		window.scrollTo(0, 0)
		const urlParams = window.location.pathname
			.replace('/speakers/', '')
			.split('/')
			.filter(Boolean)
		const id = urlParams[0]

		this.fetchSpeaker(id)
	}

	fetchSpeaker = async id => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getSpeaker', {
			params: {
				id: id,
			},
		})

		this.setState({
			speaker: data.data.speaker,
		})
		this.fetchSessions(data.data.speaker.Id)
	}

	fetchSessions = async id => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getSessions')

		const filteredSessions = data.data.sessions
			.map(session => {
				const arrayOfIds = session.Speakers.length && session.Speakers.map(speaker => speaker.Id)
				if (arrayOfIds && arrayOfIds.includes(id)) {
					return session
				}
			})
			.filter(Boolean)

		this.setState({
			sessions: filteredSessions,
		})
	}

	getImage = speaker => {
		const highResImage = speaker.PropertyValues.filter(
			(obj, i) => obj.PropertyMetadata.Title === 'High Resolution Image'
		)
		return highResImage.length ? highResImage[0].Value : speaker.PhotoLink
	}

	render() {
		const { speaker, sessions } = this.state

		return speaker ? (
			<div className='main-wrapper single-speaker'>
				<MetaTags>
					<title>
						{speaker.FirstName} {speaker.LastName} | Speakers | ASU GSV Summit
					</title>
					<meta
						name='description'
						content={`${speaker.FirstName} ${speaker.LastName}, ${speaker.Title}, ${speaker.Company}`}
					/>
					<meta name='twitter:title' content='Companies' />
					<meta
						name='twitter:description'
						content={`${speaker.FirstName} ${speaker.LastName}, ${speaker.Title}, ${speaker.Company}`}
					/>
					<meta property='og:title' content='Companies' />
					<meta
						property='og:description'
						content={`${speaker.FirstName} ${speaker.LastName}, ${speaker.Title}, ${speaker.Company}`}
					/>
				</MetaTags>
				<div className='back-button'>
					<span>
						<a href='/speakers'>Speakers / </a>
						<b>
							{speaker.FirstName} {speaker.LastName}
						</b>
					</span>
				</div>
				<div id='single-speaker-container'>
					<div
						className='company-image company-image-large speaker-image'
						style={{ backgroundImage: `url(${this.getImage(speaker)})` }}
					/>
					<section>
						<h1>
							{speaker.FirstName} {speaker.LastName}
						</h1>
						<h3>{speaker.Title}</h3>
						<h4>{speaker.Company}</h4>
						<p>{speaker.Biography}</p>
						<a href={speaker.Website} target='_blank' rel='noopener' className='single-website'>
							{speaker.Website}
						</a>
						{speaker.Twitter && (
							<a
								href={`https://twitter.com/${speaker.Twitter.replace('@', '')}`}
								className='single-social'
							>
								<img
									alt='twitter logo'
									src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5df1679c9bca423447181eaa_socialnavy_twitter.svg'
								/>
							</a>
						)}
						{speaker.LinkedIn && (
							<a href={speaker.LinkedIn} target='_blank' rel='noopener' className='single-social'>
								<img
									alt='linkedin logo'
									src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5df1679c4bb8c8751fe56d8a_socialnavy_linkedin.svg'
								/>
							</a>
						)}
					</section>
				</div>
				{/*<div className='session-container'>
					{sessions ? sessions.map((session, i) => <SessionCard session={session} key={i} />) : <Loader />}
				</div>*/}
			</div>
		) : (
			<div className='main-wrapper'>
				<Loader />
			</div>
		)
	}
}
