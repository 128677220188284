import React from 'react'

export const EventApp = () => (
	<div className='footer-wrapper '>
		<div className='event-app'>
			<div className='event-app-banner-container'>
				<h1>Download the ASU GSV Summit App</h1>
				<div className='download-buttons-containner'>
					<a href='https://apps.apple.com/us/app/asu-gsv-summit-2020/id1501300595' target='_blank' rel='noopener' className='download-app-button'>
						<img src='https://uploads-ssl.webflow.com/5de98c06bb83ab5540fc1c92/5e5e9e12c84954381529cb3e_Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' alt='Download on the App Store' />
					</a>
					<a href='https://play.google.com/store/apps/details?id=mobile.appBbX0Otwpbl' target='_blank' rel='noopener' className='download-app-button'>
						<img src='https://uploads-ssl.webflow.com/5de98c06bb83ab5540fc1c92/5e5f1d5b7dbd992d7116a3ba_google-play-badge.png' alt='Get it on Google Play' />
					</a>
				</div>
			</div>
		</div>
	</div>
)
