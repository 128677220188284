import React from 'react'

export const Paginate = props => {
	return props.large ? (
		<div id='paginate-container-large'>
			{props.startIndex !== 0 && (
				<button className='details-button details-button-inverse w-padding' onClick={props.prev}>
					<svg
						height='16px'
						width='16px'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 12 12'
						transform='translate(-2, -1)'
					>
						<path fill='none' stroke='currentColor' fillRule='evenodd' d='M8 10L4 6l4-4'></path>
					</svg>
					Previous
				</button>
			)}
			{props.maxIndex >= props.endIndex + props.inc && (
				<button className='details-button details-button-inverse' onClick={props.next}>
					Next
					<svg
						height='16px'
						width='16px'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 12 12'
						transform='translate(2, -1)'
					>
						<path fill='none' stroke='currentColor' fillRule='evenodd' d='M4 2l4 4-4 4'></path>
					</svg>
				</button>
			)}
		</div>
	) : (
		<div id='paginate-container'>
			{props.startIndex !== 0 && <button onClick={props.prev}>Previous</button>}
			{props.maxIndex >= props.endIndex + props.inc && <button onClick={props.next}>Next</button>}
		</div>
	)
}
