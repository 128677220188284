import React from 'react'
import { Loader } from './Loader'
import { Banner } from './Banner'
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { SponsorCard } from './SponsorCard'
import SponsorFilters from './SponsorFilters'

export default class Companies extends React.Component {
	state = {
		sponsors: [],
		filter: 'Sponsoring',
		filteredSponsors: [],
		sections: ['Everest', 'K2', 'McKinley', 'Kilimanjaro', 'Mont Blanc'],
	}

	componentWillMount() {
		window.scrollTo(0, 0)
		this.fetchSponsors()
	}

	fetchSponsors = async () => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getSponsors')
		this.setState({
			sponsors: data.data.sponsors,
			filteredSponsors: data.data.sponsors.filter(sponsor => sponsor.SponsorType.Title !== 'Collaborating'),
		})
	}

	filteredSponsors = filter => {
		const { sponsors } = this.state
		let filteredSponsors
		if (filter === 'Collaborating') {
			filteredSponsors = sponsors.filter(sponsor => sponsor.SponsorType.Title === 'Collaborating')
		} else {
			filteredSponsors = sponsors.filter(sponsor => sponsor.SponsorType.Title !== 'Collaborating')
		}
		this.setState({
			filteredSponsors: filteredSponsors,
			filter: filter,
		})
	}

	sortResults = data => {
		return data.sort(function(a, b) {
			if (a.CompanyName < b.CompanyName) {
				return -1
			}
			if (a.CompanyName > b.CompanyName) {
				return 1
			}
			return 0
		})
	}

	render() {
		const { filter, filteredSponsors, sections } = this.state
		const meta =
			'ASU GSV Partners are top education and talent technology organizations in the learning and talent space who support equity and access. There are two partner categories: Sponsoring and Collaborating.'

		return (
			<div className='sponsor-page'>
				<MetaTags>
					<title>Partners | ASU GSV Summit</title>
					<meta name='description' content={meta} />
					<meta name='twitter:title' content='Partners' />
					<meta name='twitter:description' content={meta} />
					<meta property='og:title' content='Partners' />
					<meta property='og:description' content={meta} />
				</MetaTags>
				<Banner
					title='sponsor partners'
					subTitle='ASU GSV Summit appreciates our sponsor partners for their dedication and contribution to the summit.'
					withButton
					image='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5df1016a5613ce4dda5c7a63_partners2.jpg'
				/>
				<div className='main-wrapper company-single-page'>
					{filteredSponsors.length ? <SponsorFilters passSponsorFilter={this.filteredSponsors} /> : ''}
					{filter === 'Sponsoring' ? (
						sections.map(section => (
							<section>
								<h1 className='sponsor-label'>{section}</h1>
								<div className='companies-grid partners-grid'>
									{filteredSponsors.length ? (
										this.sortResults(filteredSponsors)
											.filter(sponsor => sponsor.SponsorType.Title === section)
											.map((sponsor, i) => <SponsorCard key={i} sponsor={sponsor} />)
									) : (
										<Loader />
									)}
								</div>
							</section>
						))
					) : (
						<div className='companies-grid'>
							{filteredSponsors.length ? (
								this.sortResults(filteredSponsors).map((sponsor, i) => (
									<SponsorCard key={i} sponsor={sponsor} />
								))
							) : (
								<Loader />
							)}
						</div>
					)}
				</div>
			</div>
		)
	}
}
