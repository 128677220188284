import React from 'react'
import { Header } from './Header'
import MobileHeader from './MobileHeader'

export const HeaderWrapper = () => (
	<div>
		<Header />
		<MobileHeader />
	</div>
)
