import React from 'react'

export const Register = () => (
	<div className='register-banner'>
		<div className='register-banner-container'>
			<h1>ASU GSV SUMMIT</h1>
			<h2>
				MARCH 30 - APRIL 1, 2020
				<br />
				San Diego, California
			</h2>
			<a href='https://cvent.me/3qbPY' target='_blank' rel='noopener' className='details-button details-button-white'>
				Register
			</a>
		</div>
	</div>
)
