import React from 'react'
import { Link } from 'react-router-dom'

export const CompanyCard = props => {
	const { company } = props
	const highResImage = company.PropertyValues.filter(
		(obj, i) => obj.PropertyMetadata.Title === 'High Resolution Image'
	)
	const selectedImage = highResImage.length ? highResImage[0].Value : company.PhotoLink
	const generatedName = company.LastName.replace(/ /g, '-').replace(/[^a-zA-Z0-9-_]/g, '')

	return (
		<div className='company-card'>
			<Link to={`/companies/${company.Id}/${generatedName}`}>
				<div className='company-image' style={{ backgroundImage: `url(${selectedImage})` }} />
			</Link>
		</div>
	)
}
