import React from 'react'

export default class CompaniesFilters extends React.Component {
	state = {
		filter: 'GSV Cup Elite 200',
	}

	updateFilter = filter => {
		this.setState({
			filter: filter,
		})
		this.props.passCompanyFilter(filter)
	}

	render() {
		const { filter } = this.state
		return (
			<div className='filters-wrapper-large'>
				<button
					className={`details-button w-padding details-button-large session-tabs ${
						filter === 'GSV Cup Elite 200' ? 'details-button-active' : 'details-button-inverse'
					}`}
					onClick={() => this.updateFilter('GSV Cup Elite 200')}
				>
					GSV Cup Elite 200
				</button>
				<button
					className={`details-button w-padding details-button-large session-tabs ${
						filter === 'Series A+' ? 'details-button-active' : 'details-button-inverse'
					}`}
					onClick={() => this.updateFilter('Series A+')}
				>
					Series&nbsp;A+
				</button>
				<button
					className={`details-button w-padding details-button-large session-tabs ${
						filter === 'Growth' ? 'details-button-active' : 'details-button-inverse'
					}`}
					onClick={() => this.updateFilter('Growth')}
				>
					Growth
				</button>
			</div>
		)
	}
}
