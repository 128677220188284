import React from 'react'
import { Loader } from './Loader'
import { Banner } from './Banner'
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import SpeakerFilters from './SpeakerFilters'
import SpeakerCardLarge from './SpeakerCardLarge'
import { Paginate } from './Paginate'

export default class Speakers extends React.Component {
	state = {
		startIndex: 0,
		endIndex: 40,
		speakers: [],
		filteredSpeakers: [],
	}

	componentWillMount() {
		window.scrollTo(0, 0)
		this.fetchSpeakers()
	}

	fetchSpeakers = async () => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getSpeakers')

		this.setState({
			speakers: data.data.speakers,
			filteredSpeakers: data.data.speakers,
		})
	}

	getKeynoteValue = speaker => {
		const isKeynote = speaker.PropertyValues.filter((obj, i) => obj.PropertyMetadata.Title === 'Keynote speaker')
		return isKeynote.length && isKeynote[0].Value === 'yes' ? true : false
	}

	filteredSpeakers = filter => {
		const { speakers } = this.state
		let filteredSpeakers

		if (filter === 'Keynote Speakers') {
			filteredSpeakers = speakers.filter(speaker => this.getKeynoteValue(speaker))
		} else {
			filteredSpeakers = speakers
		}

		this.setState({
			filteredSpeakers: filteredSpeakers,
			startIndex: 0,
			endIndex: 40,
		})
	}

	prev = () => {
		window.scrollTo(0, 0)
		let { startIndex, endIndex } = this.state

		if (endIndex - 40 > 0) {
			this.setState({
				startIndex: (startIndex -= 40),
				endIndex: (endIndex -= 40),
			})
		}
	}

	next = () => {
		window.scrollTo(0, 0)
		let { filteredSpeakers, startIndex, endIndex } = this.state
		const maxIndex = Math.ceil(filteredSpeakers.length)

		if (startIndex + 40 <= maxIndex) {
			this.setState({
				startIndex: (startIndex += 40),
				endIndex: (endIndex += 40),
			})
		}
	}

	sortResults = data => {
		return data.sort(function(a, b) {
			if (a.LastName < b.LastName) {
				return -1
			}
			if (a.LastName > b.LastName) {
				return 1
			}
			return 0
		})
	}

	render() {
		const { filteredSpeakers, startIndex, endIndex } = this.state

		const meta =
			'ASU GSV Summit is an annual gathering of leaders in education and talent technology. Check out our 2020 speakers, including The New Yorker writer Malcolm Gladwell, and learn more about what the conference has to offer.'
		return (
			<div>
				<MetaTags>
					<title>Speakers | ASU GSV Summit</title>
					<meta name='description' content={meta} />
					<meta name='twitter:title' content='speakers' />
					<meta name='twitter:description' content={meta} />
					<meta property='og:title' content='speakers' />
					<meta property='og:description' content={meta} />
				</MetaTags>
				<Banner
					title='speakers'
					image='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e0fb11ec7ec6194379ca937_i-9ffbRqp-XL%403x.jpg'
				/>
				<div className='main-wrapper company-single-page'>
					{filteredSpeakers.length ? <SpeakerFilters passSpeakerFilter={this.filteredSpeakers} /> : ''}
					<div className='companies-grid'>
						{filteredSpeakers.length ? (
							this.sortResults(filteredSpeakers)
								.slice(startIndex, endIndex)
								.map((speaker, i) => <SpeakerCardLarge key={i} speaker={speaker} />)
						) : (
							<Loader />
						)}
					</div>
					{filteredSpeakers.length ? (
						<Paginate
							large
							next={this.next}
							prev={this.prev}
							startIndex={startIndex}
							endIndex={endIndex}
							inc={40}
							maxIndex={Math.ceil(filteredSpeakers.length)}
						/>
					) : (
						''
					)}
				</div>
			</div>
		)
	}
}
