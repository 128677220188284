import React from 'react'

export const SponsorCard = props => {
	const { sponsor } = props
	const redirect = sponsor.SponsorFields.map(field => {
		if (field.Title === 'URL for logo redirect') {
			if (field.Value.includes('https://') || field.Value.includes('http://')) {
				return field.Value
			} else if (field.Value.includes('www.')) {
				return field.Value.replace('www.', 'http://')
			} else if (!field.Value.includes('www.')) {
				return `http://${field.Value}`
			}
		}
	}).filter(Boolean)[0]

	return (
		<div className='company-card all-companies'>
			<a href={`${redirect}`} target='_blank' rel='noopener'>
				<div
					className='company-image'
					style={{ backgroundImage: `url(${sponsor.SponsorResources[0].Link})` }}
				/>
			</a>
		</div>
	)
}
