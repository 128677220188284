import React from 'react'

export const Footer = () => (
	<div className='footer-wrapper'>
		<div className='footer-container'>
			<section>
				<img
					id='footer-logo'
					alt='ASU GSV Summit 2020 logo'
					src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5de9a4104b9e5aebc1a11131_whitelogoblue-01.svg'
				/>
				<div className="footer-social-container">
					<a href="https://twitter.com/asugsvsummit" target="_blank" className='footer-social'>
						<img
							alt='twitter logo'
							src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e250c992e290c9058d831ae_socialf70177_twitter.svg'
						/>
					</a>
					<a href="https://www.linkedin.com/company/asu-gsv-summit/" target="_blank" className='footer-social'>
						<img
							alt='linkedin logo'
							src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e250c98c1ca1d57ec42e10c_socialf70177_Linkedin.svg'
						/>
					</a>
					<a href="https://www.facebook.com/asugsvsummit/" target="_blank" className='footer-social'>
						<img
							alt='facebook logo'
							src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e250c979b89b655ff445a64_socialf70177_facebook.svg'
						/>
					</a>
					<a href="https://www.youtube.com/channel/UCRcHDNWSvYi9aL9OQ5FSoNg" target="_blank" className='footer-social'>
						<img
							alt='youtube logo'
							src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e250c98acccd132eb124b71_socialf70177_youtube.svg'
						/>
					</a>
					<a href="https://www.instagram.com/asugsvsummit/" target="_blank" className='footer-social'>
						<img
							alt='instagram logo'
							src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5e250c97c1ca1d6f0242e10b_socialf70177_Instagram.svg'
						/>
					</a>
				</div>
			</section>
			<section>
				<ul>
					<li>
						<h4>Latest Updates</h4>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/newsletter'>Get updates from ASU GSV</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/talented-newsletter'>Subscribe to TalentED Newsletter</a>
					</li>
				</ul>
				<ul>
					<li>
						<h4>Resources</h4>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/news'>Latest New</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/video'>Video</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/podcast'>Podcast</a>
					</li>
					<li>
						<a href='https://gsvsummit.smugmug.com/ASU-GSV/'>Gallery</a>
					</li>
				</ul>
				<ul>
					<li>
						<h4>About</h4>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/summit-details'>Summit Detils</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/become-a-sponsor'>Sponsor</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/leadership'>Team</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/press'>Pres</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/faqs'>FAQ</a>
					</li>
				</ul>
				<ul>
					<li>
						<h4>Fund</h4>
					</li>
					<li>
						<a href='https://gsv.ventures/'>GSV Ventures</a>
					</li>
				</ul>
			</section>
		</div>
		<div className='footer-copy-wrapper'>
			<section className='footer-copy'>
				<ul>
					<li>© Global Silicon Valley 2020</li>
					<li>
						<a href='https://www.asugsvsummit.com/code-of-conduct'>Code of Conduct</a>
					</li>
					<li>
						<a href='https://www.asugsvsummit.com/terms-and-conditions'>Privacy Policy | Terms of Use</a>
					</li>
				</ul>
			</section>
		</div>
	</div>
)
