import React from 'react'

export default class Error extends React.Component {
	componentWillMount() {
		window.location.href = 'https://www.asugsvsummit.com/404'
	}

	render() {
		return <div />
	}
}
