import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import routes from './routes.js'
import { Register } from './Components/Register.js'
import { EventApp } from './Components/EventApp.js'
import { Footer } from './Components/Footer.js'
import { HeaderWrapper } from './Components/HeaderWrapper.js'
import './css/style.css'
import './css/media.css'

export default class App extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<HeaderWrapper />
				<div>{routes}</div>
				<Register />
				<EventApp />
				<Footer />
			</BrowserRouter>
		)
	}
}
