import React from 'react'
import Fuse from 'fuse.js'

export default class Search extends React.Component {
	state = {
		search: '',
		clearInput: false,
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.clearInput !== this.state.clearInput) {
			this.setState({
				search: '',
			})
		}
	}

	onChange = e => {
		const { value } = e.target

		this.setState({
			search: value,
		})
	}

	triggerSearch = () => {
		const { search } = this.state

		if (search) {
			this.searchSessions(search)
			if (this.props.fullSearch) {
				this.searchSponsors(search)
				this.searchSpeakers(search)
				this.searchCompanies(search)
			}
		}
	}

	searchSessions = value => {
		const { sessions } = this.props
		const options = {
			includeScore: true,
			shouldSort: true,
			keys: [
				{
					name: 'SessionType',
					weight: 0.2,
				},
				{
					name: 'Themes',
					weight: 0.2,
				},
				{
					name: 'Title',
					weight: 0.2,
				},
				{
					name: 'Description',
					weight: 0.2,
				},
				{
					name: 'Speakers.FirstName',
					weight: 0.2,
				},
				{
					name: 'Speakers.LastName',
					weight: 0.2,
				},
			],
		}
		const fuse = new Fuse(sessions, options)
		const results = fuse.search(value)
		const filteredResult = results.filter(result => result.score < 0.16).map(session => session.item)
		this.props.filterSessions(value ? filteredResult : [])
	}

	searchCompanies = value => {
		const { companies } = this.props
		const options = {
			includeScore: true,
			shouldSort: true,
			keys: [
				{
					name: 'LastName',
					weight: 0.2,
				},
				{
					name: 'Biography',
					weight: 0.2,
				},
				{
					name: 'Website',
					weight: 0.2,
				},
			],
		}

		const fuse = new Fuse(companies, options)
		const results = fuse.search(value)
		const filteredResult = results.filter(result => result.score < 0.16).map(company => company.item)
		this.props.filterCompanies(value ? filteredResult : [])
	}

	searchSpeakers = value => {
		const { speakers } = this.props

		const options = {
			includeScore: true,
			shouldSort: true,
			keys: [
				{
					name: 'FirstName',
					weight: 0.2,
				},
				{
					name: 'LastName',
					weight: 0.2,
				},
				{
					name: 'Title',
					weight: 0.2,
				},
				{
					name: 'Company',
					weight: 0.2,
				},
				{
					name: 'Biography',
					weight: 0.2,
				},
				{
					name: 'EmailAddress',
					weight: 0.2,
				},
				{
					name: 'Website',
					weight: 0.2,
				},
				{
					name: 'Twitter',
					weight: 0.2,
				},
				{
					name: 'LinkedIn',
					weight: 0.2,
				},
			],
		}

		const fuse = new Fuse(speakers, options)
		const results = fuse.search(value)
		const filteredResult = results.filter(result => result.score < 0.16).map(speaker => speaker.item)
		this.props.filterSpeakers(value ? filteredResult : [])
	}

	searchSponsors = value => {
		const { sponsors } = this.props
		const options = {
			includeScore: true,
			shouldSort: true,
			keys: [
				{
					name: 'CompanyName',
					weight: 0.2,
				},
			],
		}

		const fuse = new Fuse(sponsors, options)
		const results = fuse.search(value)
		const filteredResult = results.filter(result => result.score < 0.16).map(sponsor => sponsor.item)
		this.props.filterSponsors(value ? filteredResult : [])
	}

	render() {
		const { search } = this.state

		return this.props.filterOpen ? (
			<h1 id='agenda-title'>Filter Agenda</h1>
		) : (
			<div className='search-container'>
				{this.props.fullSearch ? <h1 id='search-header'>Search Results</h1> : ''}
				{this.props.fullSearch ? (
					<h2 id='search-subheader'>
						Search will display results for the program, companies, sponsors and speakers. To search content
						on the rest of the site, please visit <a href='asugsvsummit.com'>asugsvsummit.com</a>.
					</h2>
				) : (
					''
				)}
				<div className={this.props.fullSearch ? 'search-wrapper' : 'search-wrapper wrapper-no-margin'}>
					<input
						type='text'
						className='search-input w-placeholder'
						onChange={this.onChange}
						value={search}
						placeholder='Search'
					/>
					<button
						className='details-button details-button-inverse'
						onClick={this.triggerSearch}
						style={{ marginRight: '15px' }}
					>
						Search
					</button>
				</div>
			</div>
		)
	}
}
