import React from 'react'

export default class SponsorFilters extends React.Component {
	state = {
		filter: 'Sponsoring',
	}

	updateFilter = filter => {
		this.setState({
			filter: filter,
		})
		this.props.passSponsorFilter(filter)
	}

	render() {
		const { filter } = this.state
		return (
			<div className='filters-wrapper-large' style={{ marginBottom: '60px' }}>
				<button
					className={`details-button w-padding details-button-large session-tabs ${
						filter === 'Sponsoring' ? 'details-button-active' : 'details-button-inverse'
					}`}
					onClick={() => this.updateFilter('Sponsoring')}
				>
					Sponsoring
				</button>
				<button
					className={`details-button w-padding details-button-large session-tabs ${
						filter === 'Collaborating' ? 'details-button-active' : 'details-button-inverse'
					}`}
					onClick={() => this.updateFilter('Collaborating')}
				>
					Collaborating
				</button>
			</div>
		)
	}
}
