import React from 'react'
import { Link } from 'react-router-dom'

export default class SpeakerCardLarge extends React.Component {
	getImage = speaker => {
		const highResImage = speaker.PropertyValues
			? speaker.PropertyValues.filter((obj, i) => obj.PropertyMetadata.Title === 'High Resolution Image')
			: []
		return highResImage.length ? highResImage[0].Value : speaker.PhotoLink
	}

	render() {
		const { speaker } = this.props
		const name = `${speaker.FirstName} ${speaker.LastName}`
		const generatedName = name.replace(/ /g, '-').replace(/[^a-zA-Z0-9-_]/g, '')

		return (
			<Link to={`/speakers/${speaker.Id}/${generatedName}`}>
				<div id='speaker-container-large' style={{ backgroundImage: `url(${this.getImage(speaker)})` }}>
					<div className='speaker-gradient'>
						<h1>{name}</h1>
					</div>
				</div>
			</Link>
		)
	}
}
