import React from 'react'
import { Loader } from './Loader'
import SessionCard from './SessionCard'
import MetaTags from 'react-meta-tags'
import axios from 'axios'

export default class SingleCompany extends React.Component {
	state = {
		company: null,
		sessions: null,
	}

	componentWillMount() {
		window.scrollTo(0, 0)
	}

	componentDidMount() {
		const urlParams = window.location.pathname
			.replace('/companies/', '')
			.split('/')
			.filter(Boolean)
		const id = urlParams[0]

		this.fetchCompany(id)
		window.scrollTo(0, 0)
	}

	fetchSessions = async id => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getSessions')

		const filteredSessions = data.data.sessions
			.map(session => {
				const arrayOfIds = session.Speakers.length && session.Speakers.map(speaker => speaker.Id)
				if (arrayOfIds && arrayOfIds.includes(id)) {
					return session
				}
			})
			.filter(Boolean)

		this.setState({
			sessions: filteredSessions,
		})
	}

	fetchCompany = async id => {
		const { data } = await axios.get('https://gsv-2020-program-be.herokuapp.com/getCompany', {
			params: {
				id: id,
			},
		})

		this.setState({
			company: data.data.company,
		})
		this.fetchSessions(data.data.company.Id)
	}

	getImage = company => {
		const highResImage = company.PropertyValues.filter(
			(obj, i) => obj.PropertyMetadata.Title === 'High Resolution Image'
		)
		return highResImage.length ? highResImage[0].Value : company.PhotoLink
	}

	render() {
		const { company, sessions } = this.state
		return company ? (
			<div className='main-wrapper single-speaker'>
				<MetaTags>
					<title>{company.LastName} | Companies | ASU GSV Summit</title>
					<meta name='description' content={`${company.LastName} - ASU GSV Summit 2020`} />
					<meta name='twitter:title' content='Companies' />
					<meta name='twitter:description' content={`${company.LastName} - ASU GSV Summit 2020`} />
					<meta property='og:title' content='Companies' />
					<meta property='og:description' content={`${company.LastName} - ASU GSV Summit 2020`} />
				</MetaTags>
				<div className='back-button'>
					<span>
						<a href='/companies'>Companies / </a>
						<b>{company.LastName}</b>
					</span>
				</div>
				<div id='single-speaker-container'>
					<div
						className='company-image company-image-large'
						style={{ backgroundImage: `url(${this.getImage(company)})` }}
					/>
					<section>
						<h1>{company.LastName}</h1>
						<p>{company.Biography}</p>
						<a href={company.Website} target='_blank' rel='noopener' className='single-website'>
							{company.Website}
						</a>
						{company.Twitter && (
							<a
								href={`https://twitter.com/${company.Twitter.replace('@', '')}`}
								className='single-social'
							>
								<img
									alt='twitter logo'
									src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5df1679c9bca423447181eaa_socialnavy_twitter.svg'
								/>
							</a>
						)}
						{company.LinkedIn && (
							<a href={company.LinkedIn} target='_blank' rel='noopener' className='single-social'>
								<img
									alt='linkedin logo'
									src='https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5df1679c4bb8c8751fe56d8a_socialnavy_linkedin.svg'
								/>
							</a>
						)}
					</section>
				</div>
				{/*<div className='session-container'>
					{sessions ? sessions.map((session, i) => <SessionCard session={session} key={i} />) : <Loader />}
				</div>*/}
			</div>
		) : (
			<div className='main-wrapper'>
				<Loader />
			</div>
		)
	}
}
